<template>
  <SpFinder
    id="1"
    ref="finder"
    :url="{
      setting: dbSetting,
      data: dbList
    }"
    title="数据库连接配置"
    :actions="actions"
  />
</template>

<script>
import { dbList, dbSetting } from '@/api/finder';

export default {
  data () {
    return {
      actions: [
        {
          name: '创建连接',
          key: 'create',
          slot: 'header',
          type: 'button',
          buttonType: 'primary',
          action: {
            type: 'dialog',
            title: '创建连接',
            component: () => import('./detail.vue')
          }
        },
        {
          key: 'view',
          name: '查看数据表',
          type: 'button',
          action: {
            path: '/db_table',
            type: 'link'
          }
        },
        {
          key: 'edit',
          name: '编辑',
          type: 'button',
          action: {
            type: 'dialog',
            title: '编辑当前链接',
            component: () => import('./detail.vue')
          }
        },
        {
          key: 'delete',
          name: '删除',
          type: 'button',
          action: {
            type: 'api',
            api: '/ecsAdminConnection/delete',
            confirm: true
          }
        }
      ],
      dbList,
      dbSetting
    };
  },
  methods: {
    resolveChildInfo (info) {
      return {
        resourceParentId: info.id
      };
    }
  }
};
</script>

<style>

</style>
